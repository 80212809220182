import { ITree } from "../../api/rest/data/tree/types";
import { Actions } from "../type";
import { ActionTypes } from "./action";

export interface ITreeState {
    data?: ITree;
    depth: number;
    urn?: string;
    loading: boolean;
}

export const initialState: Readonly<ITreeState> = {
    data: undefined,
    depth: 0,
    loading: false,
};

export function reducer(state: ITreeState = initialState, action: Actions): ITreeState {
    switch (action.type) {
        case ActionTypes.OnLoadData: {
            return {
                ...state,
                loading: true,
            };
        }

        case ActionTypes.OnLoadDataDone: {
            return {
                ...state,
                // TODO: dirty retype - resolve by adding data to response body from server (Jakub Jirouš 29. 11. 2019)
                data: (action.payload as unknown) as ITree,
                loading: false,
            };
        }

        case ActionTypes.OnLoadDataFail: {
            return { ...state, loading: false };
        }

        case ActionTypes.OnLoadNode: {
            return {
                ...state,
                loading: true,
            };
        }

        case ActionTypes.OnLoadNodeDone: {
            return {
                ...state,
                data: (action.payload as unknown) as ITree,
                loading: false,
            };
        }

        case ActionTypes.OnLoadNodeFail: {
            return { ...state, loading: false };
        }

        default:
            return state;
    }
}
