import { Box, createStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import ForgeViewer from "iolabs-react-forge-viewer";
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import config from "../../config/config";
import { useExportForm } from "../../redux/form/hook";
import { Dispatch } from "../../redux/type";
import { onSelectUrn, useUrn } from "../../redux/viewer";
import { encodeUrnToBase64, isEmea } from "../../utils/Urn";
import { isJwtExpired } from "../../utils/Jwt";
import { token } from "../../api/rest/data/viewer/client";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "relative",
            height: "100%",
        },
        box: {
            position: "relative",
            height: "100%",
        },
        logo: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "200px",
            height: "auto",
            maxWidth: "200px",
            maxHeight: "200px",
            zIndex: 2,
            "& > img": {
                width: "100%",
            },
        },
        skeleton: {
            height: "100%",
        },
    }),
);

interface IProps {
    children?: ReactNode;
}

const Viewer: React.FC<IProps> = () => {
    const classes = useStyles();

    // selector hooks
    const urn = useUrn();
    const exportForm = useExportForm();
    const dispatch = useDispatch<Dispatch>();

    const [viewer, setViewer] = useState();
    const [view, setView] = useState();
    const [logoUrl, setLogoUrl] = useState<string | null>(null);

    const [forgeToken, setForgeToken] = useState<string>();

    useEffect(() => {
        if (exportForm !== undefined) {
            const values = exportForm?.values;

            if (values) {
                setLogoUrl(values.logo ? values.logo : null);
            }
        }
    }, [setLogoUrl, exportForm, viewer]);

    const handleViewerError = () => {
        console.log("Error loading viewer.");
    };

    const handleDocumentLoaded = (doc: any, viewables: any) => {
        if (viewables.length === 0) {
            console.error("Document contains no viewables.");
        } else {
            setView(viewables[0]);
        }
    };

    const handleDocumentError = () => {
        console.log("Error loading document.");
        dispatch(
            onSelectUrn({
                projectId: "",
                urn: "",
                urnLatest: "",
                fileName: "",
            }),
        );
    };

    const handleModelLoaded = (viewer: any) => {
        setViewer(viewer);
    };

    const handleModelError = () => {
        console.log("Error loading model.");
    };

    const handleTokenRequested = (onAccessToken: any) => {
        if (!forgeToken || isJwtExpired(forgeToken, 30)) {
            token().then(response => {
                setForgeToken(response);
                onAccessToken(response);
            });
        } else {
            onAccessToken(forgeToken);
        }
    };

    return (
        <div className={classes.root}>
            {urn ? (
                <Box className={classes.box}>
                    {logoUrl && (
                        <Box pt={2} pl={2} className={classes.logo}>
                            <img alt="Company logo URL" src={logoUrl} />
                        </Box>
                    )}
                    <ForgeViewer
                        version="7.62"
                        urn={encodeUrnToBase64(urn)}
                        api={isEmea(urn) ? "derivativeV2_EU" : "derivativeV2"}
                        view={view}
                        headless={false}
                        // proxy={config.api.forgeProxyUrl}
                        onViewerError={handleViewerError}
                        onTokenRequest={handleTokenRequested}
                        onDocumentLoad={handleDocumentLoaded}
                        onDocumentError={handleDocumentError}
                        onModelLoad={handleModelLoaded}
                        onModelError={handleModelError}
                        forgeScriptAlreadyLoaded
                    />
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                    <Skeleton variant="rect" width="100%" className={classes.skeleton} />
                </Box>
            )}
        </div>
    );
};

export default Viewer;
