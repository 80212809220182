import { AxiosResponse } from "axios";
import ApiClient from "../../ApiClient";
import { ITreeResponse } from "./types";

const treeApi = new ApiClient("/DataManagement");

export const treeDataAll = (): Promise<ITreeResponse> =>
    treeApi.post("/Tree").then((response: AxiosResponse<ITreeResponse>) => {
        return Promise.resolve(response.data);
    });

export const treeDataDepth = (depth: number): Promise<ITreeResponse> =>
    treeApi.post(`/Tree?depth=${depth}`).then((response: AxiosResponse<ITreeResponse>) => {
        return Promise.resolve(response.data);
    });

export const treeDataNode = (nodeId: string, depth: number): Promise<ITreeResponse> =>
    treeApi.post(`/Tree?nodeId=${nodeId}&depth=${depth}`).then((response: AxiosResponse<ITreeResponse>) => {
        return Promise.resolve(response.data);
    });
