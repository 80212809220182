import { Box, createStyles, Theme, Typography } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import CloudOutlinedIcon from "@material-ui/icons/CloudOutlined";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

import { Skeleton, TreeItem, TreeView } from "@material-ui/lab";
import { TreeItemProps } from "@material-ui/lab/TreeItem";
import { makeStyles } from "@material-ui/styles";
import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { ITree } from "../../api/rest/data/tree/types";
import { onLoadNode, useData, useLoading } from "../../redux/tree";
import { Dispatch } from "../../redux/type";
import { onSelectUrn } from "../../redux/viewer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        skeleton: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        skeleton10: {
            marginLeft: theme.spacing(2),
        },
        skeleton20: {
            marginLeft: theme.spacing(4),
        },
        skeleton30: {
            marginLeft: theme.spacing(6),
        },
    }),
);

declare module "csstype" {
    interface Properties {
        "--tree-view-color"?: string;
        "--tree-view-bg-color"?: string;
    }
}

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,
        },
        content: {
            color: theme.palette.text.secondary,
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightRegular,
            "$expanded > &": {
                // change style for expanded nodes
            },
        },
        group: {
            marginLeft: theme.spacing(3),
            "& $content": {
                paddingLeft: 0,
            },
        },
        expanded: {},
        label: {
            fontWeight: "inherit",
            color: "inherit",
        },
        labelRoot: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0.5, 0),
        },
        labelIcon: {
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: "inherit",
            flexGrow: 1,
        },
        activeTreeItem: {
            fontWeight: theme.typography.fontWeightBold,
        },
        activeLabel: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
        },
        activeIcon: {
            color: theme.palette.primary.main,
        },
    }),
);

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    isNodeInViewer: boolean;
};

function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, isNodeInViewer, color, bgColor, ...other } = props;

    return (
        <TreeItem
            className={isNodeInViewer ? `${classes.activeTreeItem}` : ``}
            label={
                <div
                    className={isNodeInViewer ? `${classes.activeLabel} ${classes.labelRoot}` : `${classes.labelRoot}`}
                >
                    <LabelIcon color={isNodeInViewer ? "primary" : "disabled"} className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                "--tree-view-color": color,
                "--tree-view-bg-color": bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

interface IProps {
    children?: ReactNode;
}

const Tree: React.FC<IProps> = () => {
    const classes = useStyles();
    const dispatch = useDispatch<Dispatch>();
    const treeData = useData();
    const treeLoading = useLoading();

    const [project, setProject] = useState<ITree>();
    const [parentItem, setParentItem] = useState<ITree>();
    const [nodeId, setNodeId] = useState<string>();
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [nodeInViewer, setNodeInViewer] = useState<string>();

    const handleClick = (event: React.ChangeEvent<{}>, item: ITree) => {
        setNodeId(item.id);

        // additional node data loading
        if (item.data.type !== "hub" && item.data.type !== "version" && !treeLoading && !item.loaded) {
            if (!expanded.includes(item.id)) {
                expanded.push(item.id);
                setExpanded(expanded);
            }
            dispatch(onLoadNode({ nodeId: item.id, depth: 1 }));
        }

        if (item.data.type === "project") {
            setProject(item);
        }

        if (item.data.type === "item") {
            setParentItem(item);
        }

        // save to redux store actual selected URN from tree view
        if (item.data.type === "version") {
            setNodeInViewer(item.id);
            dispatch(
                onSelectUrn({
                    projectId: project ? project.data.urn : "",
                    urn: item.data.urn,
                    urnLatest: parentItem ? parentItem.data.urn : "",
                    fileName: parentItem ? parentItem.text : "",
                }),
            );
            dispatch(submit("exportForm"));
        }
    };

    const handleChange = (event: React.ChangeEvent<{}>, nodes: string[]) => {
        setExpanded(nodes);
    };

    const renderIcon = (item: ITree) => {
        switch (item.data.type) {
            case "hub":
                return CloudOutlinedIcon;
            case "project":
                return HomeOutlinedIcon;
            case "folder":
                return FolderOpenIcon;
            case "item":
                return InsertDriveFileOutlinedIcon;
            case "version":
                return AccessTimeIcon;
            default:
                return FolderOpenIcon;
        }
    };

    const loading = (item: ITree) => {
        if (item.id === nodeId && treeLoading) {
            return (
                <Box display="flex" flexDirection="column" alignItems="flex-end" p={0}>
                    <Skeleton variant="text" width="90%" height={32} />
                    <Skeleton variant="text" width="90%" height={32} />
                </Box>
            );
        } else {
            return item.children && item.children.length > 0 ? item.children.map(renderItem) : null;
        }
    };

    const renderItem = (item: ITree) => (
        <StyledTreeItem
            key={item.id}
            nodeId={item.id}
            labelText={item.text}
            labelIcon={renderIcon(item)}
            isNodeInViewer={nodeInViewer === item.id}
            onClick={event => handleClick(event, item)}
        >
            {loading(item)}
        </StyledTreeItem>
    );

    const treeView =
        treeData && treeData.children?.length !== 0 ? (
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
                expanded={expanded}
                onNodeToggle={handleChange}
            >
                {treeData?.children?.map(renderItem)}
            </TreeView>
        ) : (
            <Box display="flex" flexDirection="column" alignItems="flex-start" mt={-1}>
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="text" width="60%" height={40} className={classes.skeleton10} />
                <Skeleton variant="text" width="60%" height={40} className={classes.skeleton20} />
                <Skeleton variant="text" width="60%" height={40} className={classes.skeleton30} />
                <Skeleton variant="text" width="60%" height={40} className={classes.skeleton30} />
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="text" width="60%" height={40} className={classes.skeleton10} />
                <Skeleton variant="text" width="60%" height={40} className={classes.skeleton10} />
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="text" width="60%" height={40} />
            </Box>
        );

    return (
        <Box pt={1.5} pl={1.5} pb={1.5} pr={0}>
            {treeView}
        </Box>
    );
};

export default Tree;
