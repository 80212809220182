import { Box, createStyles, Link, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import config from "../../config/config";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexDirection: "column",
            alignItems: "center",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
                flexWrap: "wrap",
            },
            [theme.breakpoints.up("lg")]: {
                alignItems: "flex-start",
            },
        },
        paragraph: {
            fontSize: "0.8rem",
        },
        favouriteIcon: {
            fontSize: "1.1rem",
            marginBottom: "-.2rem",
            color: theme.palette.error.main,
        },
        copyMade: {
            order: 1,
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
                order: 2,
                width: "50%",
                alignSelf: "flex-end",
                textAlign: "right",
            },
            [theme.breakpoints.up("lg")]: {
                order: 2,
                width: "auto",
            },
        },
        copyYear: {
            order: 2,
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
                order: 1,
                width: "50%",
                alignSelf: "flex-start",
                textAlign: "left",
            },
            [theme.breakpoints.up("lg")]: {
                order: 1,
                width: "auto",
                textAlign: "left",
            },
        },
    }),
);

function Footer() {
    const classes = useStyles();

    const getYear = () => {
        return new Date().getFullYear();
    };

    return (
        <Box display="flex" justifyContent="space-between" pl={1} pr={1} className={classes.root}>
            <Typography component="p" className={`${classes.paragraph} ${classes.copyMade}`}>
                <Link href={config.content.iolabsWebUrl} target="_blank" color="inherit">
                    Made with <FavoriteIcon className={classes.favouriteIcon} /> in Zürich
                </Link>
            </Typography>
            <Typography component="p" className={`${classes.paragraph} ${classes.copyYear}`}>
                <Link href={config.content.iolabsWebUrl} target="_blank" color="inherit">
                    Copyright © {getYear()} - ioLabs AG
                </Link>
            </Typography>
        </Box>
    );
}

export default Footer;
